import spinner from './assets/images/spinner.svg';
import './spinner.scss';

const Spinner = () => {
    return (
        <div className='spinner'>
            <img src={spinner} alt="spinner" />
        </div>
    );
};

export { Spinner };