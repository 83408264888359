import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import { PATHS } from 'router';

import Logo from './assets/images/logo.svg';

import './header.scss';

function Header() {
    let location = useLocation();
    let navigate = useNavigate();

    const [navMenuStatus, setNavMenuStatus] = useState('initial');

    const navRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function addTransition() {
            if (navRef.current) {
                navRef.current.style.transition = '0.3s ease-in-out 0s';
                navRef.current.addEventListener('transitionend', navRefTransitionEnd)
            }

            return null;
        }

        function navRefTransitionEnd() {
            if (navRef.current) {
                navRef.current.style.transition = '';
                navRef.current.removeEventListener('transitionend', navRefTransitionEnd)
            }

            return null;
        }

        if (navMenuStatus === 'show') {
            document.querySelector('html')!.style.overflowY = "hidden";
            addTransition();
        } else if (navMenuStatus === 'hide') {
            document.querySelector('html')!.style.overflowY = "scroll";
            addTransition();
        }
    }, [navMenuStatus]);

    function onCkickLogo() {
        navigate(PATHS.MAIN);
        if (navMenuStatus === 'show') {
            setNavMenuStatus('hide');
        }

        return null;
    }

    function onClickBurgerMenu() {
        if (navMenuStatus === 'show') {
            setNavMenuStatus('hide');
        } else {
            setNavMenuStatus('show');
        }

        return null;
    }

    function onClickNavLink(targetPath: string) {
        if (location.pathname !== targetPath) {
            setNavMenuStatus('hide')
        }

        return null;
    }

    return (
        <header className='header'>
            <div className="header__logoBlock">
                <img
                    onClick={ onCkickLogo }
                    className='header__logo'
                    src={Logo}
                    alt="Логотип сайта"
                />
            </div>
            <nav
                ref={navRef}
                className={`${navMenuStatus === 'show' ? 'header__navigation header__navigation--active' : 'header__navigation'}`}
            >
                <ul className='header__navigation-list'>
                    <li className='header__navigation-item'>
                        <Link
                            to={PATHS.MAIN}
                            onClick={() => onClickNavLink(PATHS.MAIN)}
                            className={`${location.pathname === PATHS.MAIN ? 'header__navigation-link header__navigation-link--active' : 'header__navigation-link'}`}
                        >
                            Главная
                        </Link>
                    </li>
                    <li className='header__navigation-item'>
                        <Link
                            to={ PATHS.POSTER }
                            onClick={() => onClickNavLink(PATHS.POSTER)}
                            className={`${location.pathname === PATHS.POSTER ? 'header__navigation-link header__navigation-link--active' : 'header__navigation-link'}`}
                        >
                            Афиша
                        </Link>
                    </li>
                    <li className='header__navigation-item'>
                        <Link
                            to={ PATHS.ACTIVITY }
                            onClick={() => onClickNavLink(PATHS.ACTIVITY)}
                            className={`${location.pathname === PATHS.ACTIVITY ? 'header__navigation-link header__navigation-link--active' : 'header__navigation-link'}`}
                        >
                            Мероприятия
                        </Link>
                    </li>
                    <li className='header__navigation-item'>
                        <Link
                            to={ PATHS.TEAMS }
                            onClick={() => onClickNavLink(PATHS.TEAMS)}
                            className={`${location.pathname === PATHS.TEAMS ? 'header__navigation-link header__navigation-link--active' : 'header__navigation-link'}`}
                        >
                            Коллективы
                        </Link>
                    </li>
                    <li className='header__navigation-item'>
                        <Link
                            to={ PATHS.ABOUT }
                            onClick={() => onClickNavLink(PATHS.ABOUT)}
                            className={`${location.pathname === PATHS.ABOUT ? 'header__navigation-link header__navigation-link--active' : 'header__navigation-link'}`}
                        >
                            О нас
                        </Link>
                    </li>
                    <li className='header__navigation-item'>
                        <Link
                            to={ PATHS.CONTACTS }
                            onClick={() => onClickNavLink(PATHS.CONTACTS)}
                            className={`${location.pathname === PATHS.CONTACTS ? 'header__navigation-link header__navigation-link--active' : 'header__navigation-link'}`}
                        >
                            Контакты
                        </Link>
                    </li>
                </ul>
            </nav>
            <button
                onClick={ onClickBurgerMenu }
                className={`${navMenuStatus === 'show' ? 'header__btn header__btn--active' : 'header__btn'}`}
            >
                {navMenuStatus === 'show' ? 'Закрыть меню' : 'Открыть меню'}
            </button>
        </header>
    );
};

export { Header };