import iconVk from './assets/images/vk.svg';
import iconTelegram from './assets/images/telegram.svg';
import iconMincult from './assets/images/mincult.svg';

import './footer.scss';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className="footer__links">
                <div className="footer__social">
                    <h2 className='footer__social-title'>
                        Мы в социальных сетях
                    </h2>
                    <ul className="footer__social-list">
                        <li className="footer__social-item">
                            <a href="https://vk.com/vlad_mol_centre" target="blank">
                                <span className='visually-hidden'>
                                    Наша страница Вконтакте
                                </span>
                                <img
                                    width={30}
                                    height={30}
                                    src={iconVk}
                                    alt="Логотип Вконтакте"
                                    loading="lazy"
                                />
                            </a>
                        </li>
                        <li className="footer__social-item">
                            <a href="https://t.me/mol_centr_vgmc" target="blank">
                                <span className='visually-hidden'>Телеграм канал</span>
                                <img
                                    width={30}
                                    height={30}
                                    src={iconTelegram}
                                    alt="Логотип Телеграма"
                                    loading="lazy"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="footer__sponsors">
                    <ul className='footer__sponsors-list'>
                        <li className='footer__sponsors-item'>
                            <a href="https://culture.gov.ru/" target="blank">
                                <span>
                                    Министерство культуры Российской Федерации
                                </span>
                                <img
                                    width={100}
                                    height={100}
                                    src={iconMincult}
                                    alt="Логотип МинКульта"
                                    loading="lazy"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footer__info-block">
                <div className="footer__copyright">
                    <p>
                        ©&nbsp;
                        <span>
                            {new Date().getFullYear()}
                        </span>
                        &nbsp;
                        МБУК «Владикавказский Городской Молодежный Центр им. К.Л. Хетагурова»
                    </p>
                </div>
                <div className="footer__developer">
                    <span>
                        Сайт разработан:&nbsp;
                        <a href="https://icerebro.ru/" target="blank">
                            Icerebro
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    );
};

export { Footer };