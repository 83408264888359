import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Spinner } from 'ui/spinner';

const Home = lazy(() => import('pages/home'));
const About = lazy(() => import('pages/about'));
const Activity = lazy(() => import('pages/activity'));
const Contacts = lazy(() => import('pages/contacts'));
const Poster = lazy(() => import('pages/poster'));
const Teams = lazy(() => import('pages/teams'));
const NotFoundPage = lazy(() => import('pages/notFoundPage'));

export const PATHS = {
    MAIN: '/',
    ABOUT: '/about',
    ACTIVITY: '/activity',
    CONTACTS: '/contacts',
    POSTER: '/poster',
    TEAMS: '/teams',
}

export const router = () => (
    <Suspense fallback={<Spinner />}>
        <Routes>
            <Route path={ PATHS.MAIN } element={<Home />}></Route>
            <Route path={ PATHS.ABOUT } element={<About />}></Route>
            <Route path={ PATHS.ACTIVITY } element={<Activity />}></Route>
            <Route path={ PATHS.CONTACTS } element={<Contacts />}></Route>
            <Route path={ PATHS.POSTER } element={<Poster />}></Route>
            <Route path={ PATHS.TEAMS } element={<Teams />}></Route>
            <Route path="*" element={<NotFoundPage />}></Route>
        </Routes>
    </Suspense>

)