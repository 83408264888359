import { BrowserRouter } from 'react-router-dom';
import { router } from 'router'
import { Header } from 'widgets/header';
import { Footer } from 'widgets/footer';

function App() {
    return (
        <BrowserRouter>
            <Header />
            { router() }
            <Footer />
        </BrowserRouter>
    );
}

export default App;